import { SYMBOL_MAP } from './symbols'

/**
 * Accepts a string and returns a string with all inline superscript indicators replaced with <sup> tags
 * E.g. hello world^2 becomes hello world<sup>2</sup>
 */
export const replaceForSuperScript = (text: string) => {
    return text.replace(
        /(\^\d+)/g,
        (i, match) => `<sup>${match.replace('^', '')}</sup>`
    )
}

export const replaceForSymbol = (text: string): string => {
    let result = text

    Object.entries(SYMBOL_MAP).forEach(([unicode, symbol]) => {
        const { htmlCode, optionalClass } = symbol

        // Replace normal symbols
        const symbolClass = optionalClass ? `${optionalClass}` : ''
        result = result.replaceAll(
            `~symU+${unicode}`,
            `<span class="symbol ${symbolClass}">&#${htmlCode};</span>`
        )

        // Replace superscript symbols
        result = result.replaceAll(
            `~supsymU+${unicode}`,
            `<sup>&#${htmlCode};</sup>`
        )
    })

    return result
}

export const replaceForDisclaimer = (text: string): string => {
    const regex = /~disclaimer:/g
    const matches = text.match(regex)

    if (matches) {
        matches.forEach((match) => {
            const content = match.replace(/~disclaimer:/g, '')
            text = text.replace(match, `${content}`)
        })
    }

    return text
}
